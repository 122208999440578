<template>

<app-content :loading="is.loading && !is.initialised">

	<app-content-head title="Waiting list" subtitle="Receive a notification when an already checked out game you want to play is available again in the library.">

		<app-content-head-actions-item text="Add" icon="create" v-on:click="onAddClick" v-if="collection.length" />
		
	</app-content-head>

	<app-content-body :is-call="!collection.length" :loading="is.loading && is.initialised" :is-grid="true">

		<app-content-call v-if="!collection.length" icon="checkout.notify" title="A game you want already checked out?" text="Join a waiting list for one or more games and get notified when they are available." button="Add game" v-on:click="onAddClick" />

		<app-card :brand="brandText(item)" :brandTheme="brandTheme(item)" :position="{current: item.position, total: item.waiting}" v-for="item in collection" :key="item.id" :image="item.bgg_game.image" :title="item.bgg_game.name" :subtitle="item.date_created | fromNow">
			
			<app-card-action :loading="is.leaving === item.id" :danger="true" tooltip="Click to leave waiting list" v-on:click="onLeaveClick(item.id)" icon="minus" />
			
		</app-card>

	</app-content-body>

</app-content>

</template>

<script>

import mixCollection from '@/mixin/collection'

export default {

	mixins: [mixCollection],

	data: function() {

		return {
			is: {
				leaving: false
			},
			pagination: false,
			endpoint: 'convention/checkout/notifications',
			live: 'convention/checkout/notify'
		}

	},

	methods: {

		brandText: function(item) {

			if (item.date_sent) {

				return 'Available'

			} else {

				return false

			}
			
		},

		brandTheme: function(item) {

			if (item.date_sent) {

				return 'green'

			} else {

				return false

			}
			
		},

		onAddClick: function() {

			this.$router.push({
				name: 'Convention.Checkout.Notifications.Edit',
				params: {
					id: 'new'
				}
			})

		},

		onLeaveClick: function(id) {

			this.is.leaving = id

			this.$api.delete(['convention/checkout/notification', id]).then(function() {

				this.is.leaving = false

				this.$notify({
					message: ['You have successfully left the waiting list']
				})

				this.load()

			}.bind(this))

		}

	}

}

</script>

<style scoped>

</style>